class DropdownCard {
    private element: HTMLElement;
    private closeButton: HTMLButtonElement;

    constructor() {
        this.element = document.getElementById('dropdownCard') as HTMLElement;
        this.closeButton = document.getElementById('closeButton') as HTMLButtonElement;
        this.init();
    }

    private init(): void {
        this.closeButton.addEventListener('click', () => this.hide());
        window.addEventListener('scroll', () => this.handleScroll());
    }

    private handleScroll(): void {
        if (window.scrollY >= 100) {
            this.hide();
        }
    }

    private hide(): void {
        this.element.style.opacity = '0';
        setTimeout(() => {
            this.element.style.display = 'none';
        }, 300);
    }
}

export default DropdownCard